/* ==================================== Global RMIT Colour Definitions ==================================== */
:root {
    --rmit-blue: #000054;
    --rmit-blue-hover: #0c0c64;
    --rmit-red: #E61E2A;
    --rmit-grey: #E3E5E0;
}



/* ======================================= Custom Styling rmit.css ======================================== */
.color-rmit-blue {
    color: var(--rmit-blue) !important;
}

.color-rmit-red {
    color: var(--rmit-red) !important;
}

.ui.inverted.menu {
    background: var(--rmit-blue) !important;
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .item {
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .item:hover {
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .active.item {
    background: var(--rmit-red) !important;
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .active.item:hover {
    color: var(--rmit-grey) !important;
}

.ui.grey.header {
    color: var(--rmit-blue) !important;
}

.ui.blue.header {
    color: var(--rmit-blue) !important;
}

.ui.basic.blue.button, .ui.basic.blue.buttons .button {
    box-shadow: inset 0 0 0 1px var(--rmit-blue) !important;
    color: var(--rmit-blue) !important;
}

.ui.basic.blue.button:hover, .ui.basic.blue.buttons .button:hover {
    box-shadow: inset 0 0 0 1px var(--rmit-red) !important;
    color: var(--rmit-red) !important;
}

.ui.basic.primary.button, .ui.basic.primary.buttons .button {
    box-shadow: inset 0 0 0 1px var(--rmit-blue) !important;
    color: var(--rmit-blue) !important;
}

.ui.blue.button, .ui.blue.buttons .button {
    background-color: var(--rmit-blue);
}

.ui.blue.button:hover, .ui.blue.buttons .button:hover {
    background-color: var(--rmit-red) !important;
}

.ui.blue.button:focus, .ui.blue.buttons .button:focus {
    background-color: var(--rmit-blue);
}

.ui.blue.active.button, .ui.blue.button .active.button:active, .ui.blue.buttons .active.button, .ui.blue.buttons .active.button:active {
    background-color: var(--rmit-blue);
}

.ui.teal.button:focus, .ui.teal.buttons .button:focus {
    background-color: var(--rmit-blue);
}

i.blue.icon {
    color: var(--rmit-blue) !important;
}

.ui.ordered.steps .step.active::before, .ui.steps .active.step .icon {
    color: var(--rmit-blue);
}

.ui.breadcrumb a {
    color: var(--rmit-blue);
}

.ui.breadcrumb a:hover {
    color: var(--rmit-blue-hover);
}

.ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--rmit-blue);
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background-color: var(--rmit-blue-hover);
}

.ui.header {
    color: var(--rmit-blue) !important;
}

.ui.menu {
    font-family: Museo, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

.ui.card .meta, .ui.cards > .card .meta {
    color: var(--rmit-blue) !important;
}

.ui.steps .step.active .title {
    color: var(--rmit-blue) !important;
}

.color-grey {
    color: var(--rmit-blue) !important;
}

.ui.blue.ribbon.label {
    background-color: #40407f;
    border-color:#40407f !important;
}

.ui.blue.label {
    background-color: var(--rmit-blue) !important;
}

.ui.primary.button:focus {
    background-color: var(--rmit-blue);
}

.ui.stacked.segment::after, .ui.stacked.segment::before, .ui.stacked.segments::after, .ui.stacked.segments::before { /* Hide login box double line */
    visibility: hidden;
}

.ui.list .list > a.item, .ui.list > a.item {
	color: var(--rmit-blue);
}

.ui.list .list > a.item:hover, .ui.list > a.item:hover {
	color: var(--rmit-red);
}


/* for create new workspace */
.ui.table td:has(.ui.radio) {
    text-align: center;
}

.env-type-category-grid .row .column,
.blank-images-select-grid .row .column,
.ui.table.selectable tr {
    transition-duration: .3s;
}

.env-type-category-grid .row .column:hover,
.blank-images-select-grid .row .column:hover,
.ui.table.selectable tr:hover {
    background-color: rgb(230, 230, 230);
    cursor: pointer;
}

.env-type-category-grid .row .column.selected,
.ui.table tr:has(.ui.radio.checked) {
    background-color: rgb(210, 210, 210);
}

.blank-images-select-grid,
.env-type-category-grid {
    width: 100%;
    margin: unset !important;
}

.env-type-category-grid .row .column {
    height: 90px;
    align-content: center;
    text-align: center;
    font-size: 17px;
}

.new-ws-form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    margin-bottom: 20px;
}
.new-ws-form-grid .elem {
    display: flex;
    flex-direction: column;
}
.new-ws-form-grid .elem > textarea {
    height: 6.5em;
}
.new-ws-form-grid .elem > *:last-child {
    margin-top: auto;
}
.new-ws-form-grid .elem:nth-child(odd) {
    grid-column: 1;
}
.new-ws-form-grid .elem:nth-child(even) {
    grid-column: 2;
}

.new-ws-form-grid .elem input::placeholder,
.new-ws-form-grid .elem textarea::placeholder {
    color: hsla(0, 0%, 20.8%, 0.87);
}

.select-env-table-radio { width: 40px; }
.select-env-table-name { width: 30%; }

.blank-images-select-grid .row .column {
    text-align: center;
    align-content: center;
    font-size: 17px;
    padding: 30px 0px;
}
.blank-images-select-grid .row .column img {
    margin: auto;
    margin-bottom: 30px;
    width: 80px;
}

.ui[class*="right labeled"].icon.button.go-next {
    padding-right: 2.6em !important;
}

.ui.labeled.icon.button.go-back {
    padding-left: 3.5em !important;
}

.category-menu {
    display: flex;
    margin: 1em 0px;
    width: 100%;
    height: 3em;
    font-weight: 400px;
    overflow-x: auto;
}

.category-menu .item {
    height: 100%;
    width: fit-content;
    padding: 0px 2em;
    text-align: center;
    align-content: center;
    transition-duration: .1s;
    border: 1px solid;
    border-right: unset;
    box-sizing: border-box;
    text-wrap: nowrap;
    user-select: none;
}
.category-menu .item:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
.category-menu .item:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-right: 1px solid;
}
.category-menu .item:hover {
    cursor: pointer;
}

.category-menu .item.selected {
    font-weight: bold;
}

.category-menu .item.color-scheme-red { color: red; }
.category-menu .item.color-scheme-red.selected { color: white; border-color: red; background-color: red; }
.category-menu .item.color-scheme-blue { color: dodgerblue; }
.category-menu .item.color-scheme-blue.selected { color: white; border-color: dodgerblue; background-color: dodgerblue; }
.category-menu .item.color-scheme-orange { color: orange; }
.category-menu .item.color-scheme-orange.selected { color: white; border-color: orange; background-color: orange; }
.category-menu .item.color-scheme-green { color: #21ba45; }
.category-menu .item.color-scheme-green.selected { color: white; border-color: #21ba45; background-color: #21ba45; }
.category-menu .item.color-scheme-purple { color: purple; }
.category-menu .item.color-scheme-purple.selected { color: white; border-color: purple; background-color: purple; }
.category-menu .item.color-scheme-brown { color: crimson; }
.category-menu .item.color-scheme-brown.selected { color: white; border-color: crimson; background-color: crimson; }
.category-menu .item.color-scheme-olive { color: #b5cc18; }
.category-menu .item.color-scheme-olive.selected { color: white; border-color: #b5cc18; background-color: #b5cc18; }
.category-menu .item.color-scheme-black { color: black; }
.category-menu .item.color-scheme-black.selected { color: white; border-color: black; background-color: black; }

/* for general layout */

/* ai chatbot */
.fixed-position-icon {
    --icon-size: 5em;
    position: fixed;
    z-index: 100;
    bottom: 30px;
    right: 30px;
    border: 1px solid var(--rmit-blue);
    color: var(--rmit-blue);
    height: var(--icon-size);
    width: var(--icon-size);
    background-color: var(--rmit-red);
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: width .3s, height .3s, background-color 0s .3s;
}
.fixed-position-icon:not(.show-chat):hover { cursor: pointer; }

.fixed-position-icon:not(.show-chat) > i {
    margin: auto;
    font-size: 2em !important;
}
.fixed-position-icon:not(.show-chat) > svg {
    margin: auto;
}

.fixed-position-icon.show-chat {
    width: 35em;
    height: 50em;
    max-width: 100dvw;
    max-height: 100dvh;
    background-color: rgba(0,0,0,.1);
    backdrop-filter: blur(4px);
    transition: width .3s, height .3s;
}

@media screen and (max-width: 800px) {
    .fixed-position-icon.show-chat {
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
    }
}

.fixed-position-icon.show-chat > .chat-page {
    width: 100%;
    height: 100%;
}

.fixed-position-icon.show-chat > .chat-page > .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
}

.fixed-position-icon.show-chat > .chat-page > .close-icon:hover { cursor: pointer; }

.fixed-position-icon.show-chat > .chat-page > .conversations {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    padding: 35px 10px 20px 10px;
    overflow-y: auto;
}

.fixed-position-icon.show-chat > .chat-page > .conversations > .greeting {
    width: 80%;
    margin: auto;
    text-align: center;
}

.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble {
    display: flex;
    margin-bottom: 15px;
    align-items: end;

    --avatar-size: 30px;
}

.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble > .content {
    border-radius: 15px;
    width: fit-content;
    max-width: calc(100% - (var(--avatar-size) + 20px) * 2);
    background-color: white;
    color: var(--rmit-blue);
    padding: 5px 10px;
    min-width: 30px;
}

.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble > .avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    margin: 0px 10px;
    display: flex;
    background-color: white;
    border-radius: 50px;
}

.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble > .avatar * {
    margin: auto;
    width: 70%;
    height: 70%;
    font-size: 90%;
}

.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble.hidden {
    display: none;
}
.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble.assistant {
    flex-direction: row;
}
.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble.assistant > .content {
    border-bottom-left-radius: unset;
}
.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble.user {
    flex-direction: row-reverse;
}
.fixed-position-icon.show-chat > .chat-page > .conversations > .bubble.user > .content {
    margin-right: 0px;
    border-bottom-right-radius: unset;
    background-color: var(--rmit-blue);
    color: white;
}

.fixed-position-icon.show-chat > .chat-page > .conversations > .reset-conversation {
    margin: auto;
    margin-top: 10px;
    color: var(--rmit-blue);
    border: 1px solid;
    border-radius: 5px;
    padding: 3px 10px;
    width: fit-content;
}
.fixed-position-icon.show-chat > .chat-page > .conversations > .reset-conversation:hover {
    cursor: pointer;
}

@keyframes dotAnimation {
    0% { color: rgb(90, 90, 90); }
    50% { color: rgb(150, 150, 150); }
    100% { color: rgb(210, 210, 210); }
}

.fixed-position-icon.show-chat > .chat-page > 
.conversations > .bubble.assistant .dots > .loading-dot {
    font-size: 11px;
    animation: dotAnimation 1.2s infinite linear;
}

.fixed-position-icon.show-chat > .chat-page > 
.conversations > .bubble.assistant .dots > .loading-dot:nth-child(2) { animation-delay: .4s; }

.fixed-position-icon.show-chat > .chat-page > 
.conversations > .bubble.assistant .dots > .loading-dot:nth-child(3) { animation-delay: .8s; }

.fixed-position-icon.show-chat > .chat-page > .chat-input {
    --input-height: 40px;

    position: relative;
    width: 100%;
    height: calc(var(--input-height) + 20px);
    padding: 10px 15px;
}

.fixed-position-icon.show-chat > .chat-page > .chat-input > input {
    width: 100%;
    height: 100%;
    border: unset;
    border-radius: 15px;
    padding: 0px 13px;
    padding-right: 53px;
    font-size: 14px;
    z-index: 1;
}
.fixed-position-icon.show-chat > .chat-page > .chat-input > input:focus {
    outline: none;
}

.fixed-position-icon.show-chat > .chat-page > .chat-input > .icon-container {
    position: absolute;
    right: 15px;
    top: 10px;
    width: var(--input-height);
    height: var(--input-height);
    display: flex;
    align-items: center;
}
.fixed-position-icon.show-chat > .chat-page > .chat-input > .icon-container > .submit-btn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}
.fixed-position-icon.show-chat > .chat-page > .chat-input > .icon-container > .submit-btn.disabled {
    pointer-events: none;
}
.fixed-position-icon.show-chat > .chat-page > .chat-input > .icon-container > .icon {
    width: 20px;
    height: 20px;
    margin: auto;
    z-index: 1;
}
.fixed-position-icon.show-chat > .chat-page > .chat-input > .icon-container > *:hover {
    cursor: pointer;
}

.range-elem-main {
    display: flex;
    align-items: center;
}

.range-elem-main > .range-input {
    width: 80%;
    flex-basis: 80%;
    margin-right: 10px;
}

.range-elem-main > .text-input {
    width: calc(20% - 10px);
    flex-basis: calc(20% - 10px);
    min-width: 4em;
}

.range-elem-main > .text-input > input{
    text-align: center;
}

/* .race-documentation-if {
    margin: 1em;
    margin-bottom: unset;
    width: calc(100% - 2em);
    height: calc(100% + 100px - 2em);
    border-radius: 7px;
    border: 1px solid lightgray;
} */

.mainLayout:has(.race-documentation-if) {
    overflow: hidden;
}

.race-documentation-if {
    width: 100%;
    height: calc(100% + 100px);
    border: unset;
}

.study-file-download > i {
    color: var(--rmit-blue);
    margin-right: 3px;
}
.study-file-download:hover {
    cursor: pointer;
    text-decoration: underline;
}